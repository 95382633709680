<template>
  <Callout v-if="INCIDENT_MESSAGE" variant="error">
    {{ INCIDENT_MESSAGE }}

    <template #cta>
      <a href="https://status.buttondown.com" target="_blank">
        Status page ↗
      </a>
    </template>
  </Callout>
  <Callout
    v-if="newsletter?.paid_subscriptions_status === 'disabled'"
    variant="error"
  >
    Stripe has disabled paid subscriptions for your newsletter.
    <template #cta>
      <a href="https://dashboard.stripe.com/account/status" target="_blank">
        Stripe ⤴
      </a>
    </template>
  </Callout>
  <Callout v-if="newsletter?.status === 'permanently_disabled'" variant="error">
    Your account has been disabled pending more information about your account;
    please check your email for more information.
  </Callout>
  <Callout v-if="newsletter?.status === 'unconfirmed'" variant="info">
    You'll need to confirm your email address before subscribers can confirm
    their subscription.
    <template #cta>
      <div class="cursor-pointer" @click="resend">Resend</div>
    </template>
  </Callout>
  <Callout v-if="newsletter?.status === 'dormant'" variant="info">
    This newsletter is currently dormant.
    <template #cta>
      <RouterLink :to="{ name: '/settings/danger-zone' }">
        Re-enable newsletter
      </RouterLink>
    </template>
  </Callout>
  <div class="flex min-h-0 grow flex-col bg-black" v-bind="$attrs">
    <div
      id="app-scaffold"
      :class="{
        '!flex !flex-row origin-center-top transition-all bg-white h-full': true,
        'scale-95 md:scale-100 opacity-80 md:opacity-100': showingDialog,
      }"
    >
      <Sidebar
        v-if="showingSidebar && !!newsletter && !!account"
        :class="{ 'flex-1': isWidescreen }"
        :newsletter="newsletter"
        :account="account"
        @trigger-search="showSearchModal = true"
      />

      <SplitterGroup
        :auto-save-id="LocalStorageKey.APP_PANELS"
        direction="horizontal"
        :class="{ grow: true, '!hidden': showingSidebar && isWidescreen }"
      >
        <SplitterPanel
          id="main"
          :class="{
            'container flex-col relative overflow-y-scroll !max-w-full': true,
            flex: !(showingDrawer !== 'no' && isMobile),
            hidden: showingDrawer !== 'no' && isMobile,
          }"
        >
          <div class="flex flex-col h-full @container/panel">
            <RouterView />
          </div>
        </SplitterPanel>

        <SplitterResizeHandle
          v-if="showingDrawer === 'yes' && !isMobile"
          class="group border-l border-l-gray-100"
        >
          <div
            class="group-focus:outline absolute border border-neutral-400 bg-neutral-200 -ml-[9px] z-20 rounded top-[calc(50%-1rem)] p-0.5 hover:bg-neutral-300 transition-colors cursor-grab"
            @dblclick="onHandleDblClick"
          >
            <Bars3Icon class="size-3 rotate-90 text-neutral-600" />
          </div>
        </SplitterResizeHandle>

        <SplitterPanel
          v-if="showingDrawer !== 'no'"
          id="drawer"
          ref="drawerPanelRef"
          data-target="drawer-container"
          style="filter: drop-shadow(-10px 0px 20px rgba(0, 0, 0, 0.2))"
          :max-size="showingDrawer === 'takeover' ? 80 : 50"
          :min-size="showingDrawer === 'takeover' ? 80 : 25"
          :default-size="showingDrawer === 'takeover' ? 80 : 50"
        ></SplitterPanel>
      </SplitterGroup>
    </div>
    <NotificationsModal
      v-if="relevantNotifications.length > 0"
      :notifications="relevantNotifications"
    />

    <BulkActionModal v-if="draftBulkAction" :bulk-action="draftBulkAction" />
    <ErrorModal v-for="error in errors" :key="error.detail" :error="error" />
    <PaidFeature v-if="showingPaidFeatureDialog" feature="custom_domain" />

    <div
      v-if="
        newsletter &&
        (currentDomain === 'admin.buttondown.com' ||
          currentDomain === '127.0.0.1')
      "
      :class="{
        'bg-gray-800 fixed bottom-0 right-0 left-auto text-white z-50 font-mono text-sm px-2 py-1 shadow-lg rounded-tl-md items-center gap-2 cursor-default': true,
        'opacity-50 flex hover:opacity-100': !showMoreInfo,
        '!opacity-100 space-y-2 py-2 px-4 !left-0 rounded-tl-none':
          showMoreInfo,
      }"
      @click="showMoreInfo = !showMoreInfo"
    >
      <svg
        v-if="!showMoreInfo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        class="size-4"
      >
        <path
          d="M8.372 1.349a.75.75 0 0 0-.744 0l-4.81 2.748L8 7.131l5.182-3.034-4.81-2.748ZM14 5.357 8.75 8.43v6.005l4.872-2.784A.75.75 0 0 0 14 11V5.357ZM7.25 14.435V8.43L2 5.357V11c0 .27.144.518.378.651l4.872 2.784Z"
        />
      </svg>

      <div class="flex gap-4">
        <div>
          <div v-if="showMoreInfo" class="text-xs text-gray-400">Username</div>
          {{ newsletter?.username }}
        </div>
        <div v-if="showMoreInfo">
          <div class="text-xs text-gray-400">ID</div>
          <a
            :href="`/admin/emails/newsletter/${newsletter?.id}/change/`"
            target="_blank"
            class="text-white hover:bg-blue-600 whitespace-nowrap"
          >
            {{ newsletter?.id }}
          </a>
        </div>
        <div v-if="showMoreInfo">
          <div class="text-xs text-gray-400 whitespace-nowrap">
            Stripe customer
          </div>
          <a
            :href="`https://dashboard.stripe.com/customers/${account?.stripe_customer_id}`"
            target="_blank"
            class="text-white hover:bg-blue-600"
          >
            {{ account?.stripe_customer_id || "(none)" }}
          </a>
        </div>
        <div v-if="showMoreInfo">
          <div class="text-xs text-gray-400">Status</div>
          {{ newsletter?.status }}
        </div>
        <div v-if="showMoreInfo">
          <div class="text-xs text-gray-400">Shard</div>
          {{ newsletter?.shard }}
        </div>
        <div v-if="showMoreInfo">
          <div class="text-xs text-gray-400 whitespace-nowrap">
            Delivery provider
          </div>
          {{ newsletter?.delivery_provider }}
        </div>
        <div v-if="showMoreInfo">
          <div class="text-xs text-gray-400 whitespace-nowrap">
            Billing plan
          </div>
          {{ account?.billing_plan }} / {{ account?.billing_type }}
        </div>
      </div>
      <div
        v-if="Object.keys(account?.flags || {}).length > 0 && showMoreInfo"
        class="flex gap-4"
      >
        <div class="whitespace-nowrap overflow-x-auto">
          <div class="text-xs text-gray-400 whitespace-nowrap">Flags</div>
          <span
            v-for="flag in Object.entries(account?.flags || {})"
            :key="flag[0]"
            class="inline-block mr-3"
          >
            {{ flag[0] }}
          </span>
        </div>
      </div>
      <div v-if="newsletter?.stripe_account && showMoreInfo" class="flex gap-4">
        <div>
          <div class="text-xs text-gray-400 whitespace-nowrap">
            Stripe account
          </div>
          <a
            :href="`https://dashboard.stripe.com/${newsletter?.stripe_account}`"
            target="_blank"
            class="text-white hover:bg-blue-600"
          >
            {{ newsletter?.stripe_account }}
          </a>
        </div>
        <div>
          <div class="text-xs text-gray-400 whitespace-nowrap">
            Paid subscriptions status
          </div>
          {{ newsletter?.paid_subscriptions_status }}
        </div>
      </div>
    </div>
  </div>

  <SearchModal v-if="showSearchModal" @close="showSearchModal = false" />
  <ToastRenderer />
</template>

<script lang="ts">
import axios from "axios";
import { SplitterGroup, SplitterPanel, SplitterResizeHandle } from "radix-vue";
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router/auto";

import URLS from "@/autogen/urls";
import BulkActionModal from "@/components/BulkActionModal/Omnibus.vue";
import PaidFeature from "@/components/Utilities/PaidFeature.vue";
import Callout from "@/design_system/Callout.vue";
import Bars3Icon from "@/icons/heroicons/bars-3-outline.svg";
import { useKeybinds } from "@/lib/hotkeys";
import { useMediaQuery } from "@/lib/media_query";
import { showToast } from "@/lib/toasts";
import NotificationsModal from "@/scaffolding/NotificationsModal.vue";
import { LocalStorageKey } from "@/store/local_storage";
import { useStore as useBulkActionsStore } from "@/store/stores/bulk_actions";
import { useStore as useErrorsStore } from "@/store/stores/errors";
import { useStore as useNotificationsStore } from "@/store/stores/notifications";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";
import { Notification } from "@/types/notification";

import ErrorModal from "./ErrorModal.vue";
import SearchModal from "./SearchModal/Container.vue";
import Sidebar from "./Sidebar/Component.vue";
import ToastRenderer from "./ToastRenderer.vue";

declare const INCIDENT_MESSAGE: string;

export default defineComponent({
  name: "App",
  components: {
    Bars3Icon,
    BulkActionModal,
    Callout,
    ErrorModal,
    NotificationsModal,
    PaidFeature,
    SearchModal,
    Sidebar,
    SplitterGroup,
    SplitterPanel,
    SplitterResizeHandle,
    ToastRenderer,
  },
  setup() {
    window.document.title = "Buttondown";

    const notificationsStore = useNotificationsStore();
    const scaffoldingStore = useScaffoldingStore();
    const bulkActionsStore = useBulkActionsStore();
    const errorsStore = useErrorsStore();

    const route = useRoute();
    const isWidescreen = useMediaQuery("(width < 1024px)");
    const isMobile = useMediaQuery("(width < 768px)");

    watch(
      [isWidescreen, () => route.fullPath],
      ([isOnWidescreen]) => {
        if (isOnWidescreen) {
          scaffoldingStore.showingSidebar = false;
        }
      },
      { immediate: true }
    );
    watch(isWidescreen, (isOnWidescreen) => {
      if (!isOnWidescreen) {
        scaffoldingStore.showingSidebar = true;
      }
    });

    const drawerPanelRef = ref<any>();
    const onHandleDblClick = (ev: MouseEvent) => {
      // Only accept double clicks from primary button
      if (ev.button !== 0) {
        return;
      }

      // <SplitterPanel> components don't give us access to their elements via
      // refs, so we'll query the data-target attribute set on the drawer.
      const drawerEl: HTMLElement | null = document.querySelector(
        "[data-target=drawer-container]"
      )!;
      const splitterEl: HTMLElement = drawerEl.parentElement!;

      // Capture the previous values for rollback.
      const { flex: prevFlex, width: prevWidth } = drawerEl.style;

      // These resizable panels work by setting our defined percentage values
      // into `flex-grow`, nifty use of flexbox! Let's override them so we know
      // what the minimum appropriate size for the drawer should've been.
      Object.assign(drawerEl.style, {
        flexGrow: "0",
        flexBasis: "auto",
        width: "min-content",
      });

      // Add 1px to account for the resize divider, just in case.
      const pixelWidth = drawerEl.offsetWidth;
      const desiredWidth = (pixelWidth + 1) / splitterEl.offsetWidth;

      // Make sure we don't go over 50% as set above, then multiply by 100
      const clampedWidth = Math.trunc(Math.min(0.5, desiredWidth) * 100);

      // We're done here, so let's unset the styles we've temporarily set.
      // If the resize went through it should've reset the `flex` property, but
      // if not, then it'll remain be stuck at our temporary values.
      Object.assign(drawerEl.style, { flex: prevFlex, width: prevWidth });

      drawerPanelRef.value.resize(clampedWidth);
    };

    const showMoreInfo = ref(false);

    const showSearchModal = ref(false);
    useKeybinds({
      "$mod+k"(ev) {
        if (ev.defaultPrevented) {
          return;
        }

        showSearchModal.value = true;
        return true;
      },
    });

    const currentDomain = computed(() => window.location.hostname);

    const pendingResend = ref(false);

    const resend = async () => {
      pendingResend.value = true;

      try {
        await axios.post(URLS["resend-confirmation-email"]());
      } finally {
        pendingResend.value = false;
      }

      showToast({
        type: "error",
        title: `Confirmation email sent`,
        message: `Check your inbox to confirm your account.`,
      });
    };

    return {
      resend,
      isWidescreen,
      isMobile,
      drawerPanelRef,
      showMoreInfo,
      showSearchModal,
      onHandleDblClick,
      notifications: computed(() => notificationsStore.notifications),
      listNotifications: notificationsStore.list,
      showingSidebar: computed(() => scaffoldingStore.showingSidebar),
      showingDialog: computed(() => scaffoldingStore.showingDialog),
      showingPaidFeatureDialog: computed(
        () => scaffoldingStore.showingPaidFeatureDialog
      ),
      setPaidFeatureDialog: scaffoldingStore.setPaidFeatureDialog,
      draftBulkAction: computed(() => bulkActionsStore.draft),
      account: computed(() => scaffoldingStore.account),
      newsletter: computed(() => scaffoldingStore.newsletter),
      errors: computed(() => errorsStore.resource),
      showingDrawer: computed(() => scaffoldingStore.showingDrawer),
      currentDomain,
      INCIDENT_MESSAGE,
      LocalStorageKey,
    };
  },
  computed: {
    relevantNotifications(): Notification[] {
      return this.notifications.filter((notification: Notification) => {
        return (
          // Friendly reminder: `this.$route.name` is the name of the current route, and corresponds to the `name` prop of `<router-link>`.
          // the keys of `RouteNamedMap` in `typed-router.d.ts`. Using `name` instead of `path` allows us to reference `/emails.[id]`, for instance,
          // because `path` would be something like `/emails/497f6eca-6276-4993-bfeb-53cbbbba6f08`.
          notification.route === null || notification.route === this.$route.name
        );
      });
    },
  },

  watch: {
    newsletter: {
      handler() {
        if (this.newsletter) {
          this.listNotifications();
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss">
@import "@/styles/base";
</style>
